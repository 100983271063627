import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InviteRequest } from '@werfy/data/invite';
import {
  catchError,
  firstValueFrom,
  map,
  Observable,
  of,
  ReplaySubject,
  skip,
  Subject,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { LinkedinJoinOrganizationRequest, LinkedinLoginRequest } from '../requests';
import { ApiResources, AuthenticationResources } from '../resources';
import { UserResponse } from '../responses';
import { AuthenticationStore } from '../stores';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private _isLoggedIn$: Subject<boolean> = new ReplaySubject<boolean>();
  private _isLoggedIn?: boolean;

  constructor(
    private apiService: ApiService,
    private authenticationStore: AuthenticationStore,
  ) {
    const isExtension = window.location.search.includes('extension=true');
    if (authenticationStore.isLoggedIn() || isExtension) {
      this.getUser$().subscribe();
    } else {
      this.setLoggedIn(false).then();
    }
  }

  public isLoggedIn(): boolean | undefined {
    return this._isLoggedIn;
  }

  public isLoggedIn$(): Observable<boolean> {
    return this._isLoggedIn$.asObservable();
  }

  public signIn$(linkedinCode: string): Observable<void> {
    const url: string = this.createUrl(AuthenticationResources.SignIn);
    const data: LinkedinLoginRequest = { code: linkedinCode };

    return this.apiService.post$<void>(url, data).pipe(switchMap(() => this.getUser$()));
  }

  public signUp$(linkedinCode: string, request: InviteRequest): Observable<void> {
    const url: string = this.createUrl(AuthenticationResources.SignUp);
    const data: LinkedinJoinOrganizationRequest = {
      linkedinCode: linkedinCode,
      inviteCode: request.code,
      inviteEmail: request.email,
    };

    return this.apiService.post$<void>(url, data).pipe(switchMap(() => this.getUser$()));
  }

  public signOut$(): Observable<void> {
    const url: string = this.createUrl(AuthenticationResources.SignOut);

    return this.apiService.delete$<void>(url).pipe(
      catchError(() => of(undefined)),
      tap(() => this.setLoggedIn(false)),
    );
  }

  public getUser$(): Observable<void> {
    const url = `${AuthenticationResources.Users}/me`;

    return this.apiService.get$<UserResponse>(url).pipe(
      map((user: UserResponse) => this.authenticationStore.setUser(user)),
      tap(() => this.setLoggedIn(true)),
    );
  }

  public refresh$(): Observable<void> {
    const url: string = this.createUrl(AuthenticationResources.Refresh);

    return this.apiService.post$<void>(url).pipe(
      catchError(async (error: unknown): Promise<Observable<void>> => {
        if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.Unauthorized) {
          await this.setLoggedIn(false);
        }
        return throwError(() => error);
      }),
      switchMap(() => this.getUser$()),
    );
  }

  private async setLoggedIn(isLoggedIn: boolean): Promise<void> {
    this._isLoggedIn = isLoggedIn;
    this.authenticationStore.setLoggedIn(isLoggedIn);
    this._isLoggedIn$.next(isLoggedIn);

    await firstValueFrom(this._isLoggedIn$.pipe(skip(1)));
  }

  private createUrl(resource: string): string {
    return `${ApiResources.Authentication}/${resource}`;
  }
}
