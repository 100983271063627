import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'wp-select-option',
  templateUrl: 'select-option.component.html',
  styleUrl: 'select-option.component.scss',
})
export class SelectOptionComponent {
  @ViewChild('select') public select!: ElementRef<HTMLDivElement>;

  @Input() public value!: string;

  @Output() public selectOption: EventEmitter<void> = new EventEmitter<void>();

  public isSelected: boolean = false;

  public onSelect(): void {
    this.selectOption.emit();
  }

  public getLabel(): string {
    return this.select.nativeElement.innerHTML;
  }
}
