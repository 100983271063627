import { NgIf } from '@angular/common';
import {
  AfterContentInit,
  Component,
  ContentChildren,
  forwardRef,
  Input,
  OnDestroy,
  Optional,
  QueryList,
  Self,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { InputErrorComponent, TagOptionComponent } from '@werfy/ui';
import { Subscription } from 'rxjs';
import { InputComponent, InputInterface } from '../input';

@Component({
  selector: 'wp-single-tag-input',
  templateUrl: 'single-tag-input.component.html',
  styleUrl: 'single-tag-input.component.scss',
  imports: [InputErrorComponent, NgIf],
  providers: [{ provide: InputComponent, useExisting: forwardRef(() => SingleTagInputComponent), multi: true }],
})
export class SingleTagInputComponent implements ControlValueAccessor, InputInterface, AfterContentInit, OnDestroy {
  @ContentChildren(TagOptionComponent) public options!: QueryList<TagOptionComponent>;

  @Input() public name?: string;

  public isDisabled: boolean = false;
  public subscriptions$: Subscription = new Subscription();
  public onChange!: (value: string | null) => void;
  public onTouched!: () => void;
  private value: string | null = null;

  constructor(@Optional() @Self() public control: NgControl) {
    if (control) {
      control.valueAccessor = this;
    }
  }

  public ngAfterContentInit(): void {
    this.options.notifyOnChanges();
    this.options.changes.subscribe((): void => this.listenToOptions());
    this.listenToOptions();
  }

  public ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }

  public registerOnChange(onChange: (value: string | null) => void): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public writeValue(value: string | null): void {
    this.value = value;
  }

  private listenToOptions(): void {
    this.subscriptions$.unsubscribe();
    this.subscriptions$ = new Subscription();
    this.options.forEach((option: TagOptionComponent) => {
      const selectSubscription$ = option.select.subscribe(() => {
        this.onSelect(option);
      });
      this.subscriptions$.add(selectSubscription$);
    });
    this.updateOptionsSelection();
  }

  private updateOptionsSelection(): void {
    this.options.forEach((option: TagOptionComponent) => {
      option.isSelected = option.value === this.value;
    });
  }

  private onSelect(selectedOption: TagOptionComponent): void {
    this.options.forEach((option: TagOptionComponent): void => {
      option.isSelected = option === selectedOption;
    });

    this.value = selectedOption.value;
    this.onChange(this.value);
    this.onTouched();
  }
}
