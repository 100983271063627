export const ApplicationRoutes = {
  Root: '/',
  JoinOrganization: 'join-organization',
  Authentication: 'authentication',
  Tickets: 'tickets',
  Campaigns: 'campaigns',
  Candidates: 'candidates',
  Profiles: 'profiles',
  Reporting: 'reporting',
  Templates: 'templates',
  Dashboard: 'dashboard',
  Styleguide: 'styleguide',
  Organizations: 'organizations',
} as const;
