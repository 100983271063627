import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { UserResponse } from '../responses';
import { Store } from './store';

const LOGGED_IN_KEY = 'is-logged-in';
const JOIN_CODE = 'join-code';
const JOIN_EMAIL = 'join-email';

@Injectable({ providedIn: 'root' })
export class AuthenticationStore {
  private user$: Subject<UserResponse | undefined> = new BehaviorSubject<UserResponse | undefined>(undefined);

  constructor(private store: Store) {}

  public setLoggedIn(isLoggedIn: boolean): void {
    this.store.set<boolean>(LOGGED_IN_KEY, isLoggedIn);
  }

  public isLoggedIn(): boolean {
    return this.store.get<boolean | undefined>(LOGGED_IN_KEY) === true;
  }

  public getUser$(): Observable<UserResponse | undefined> {
    return this.user$.asObservable();
  }

  public setUser(user: UserResponse): void {
    this.user$.next(user);
  }

  public setJoinOrganization(code: string, email: string): void {
    this.store.set<string>(JOIN_CODE, code);
    this.store.set<string>(JOIN_EMAIL, email);
  }

  public clearJoinOrganization(): void {
    this.store.clear(JOIN_CODE);
    this.store.clear(JOIN_EMAIL);
  }

  public getJoinCode(): string | undefined {
    return this.store.get<string>(JOIN_CODE);
  }

  public getJoinEmail(): string | undefined {
    return this.store.get<string>(JOIN_EMAIL);
  }
}
