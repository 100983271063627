import { Component, Input } from '@angular/core';
import { IconComponent } from '../../core';

@Component({
  selector: 'wp-loader',
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss',
  imports: [IconComponent],
})
export class LoaderComponent {
  @Input() public orientation: 'horizontal' | 'vertical' = 'horizontal';
}
