import { NgForOf } from '@angular/common';
import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { TabComponent } from '../tab';

@Component({
  selector: 'wp-tab-group',
  templateUrl: 'tab-group.component.html',
  styleUrl: 'tab-group.component.scss',
  imports: [NgForOf],
})
export class TabGroupComponent implements AfterContentInit {
  @ContentChildren(TabComponent) public tabs!: QueryList<TabComponent>;

  @Input() public activeTab?: string;

  @Output() public activeTabChange: EventEmitter<string> = new EventEmitter<string>();

  public ngAfterContentInit(): void {
    if (this.tabs.length === 0) {
      throw Error('Tabs required');
    }
    if (!this.activeTab) {
      this.activeTab = this.tabs.first.title;
    }

    this.checkActiveTab(this.activeTab ?? '');
  }

  public onSelectTab(tab: TabComponent): void {
    this.activeTabChange.emit(tab.title);
    this.activeTab = tab.title;
    this.checkActiveTab(tab.title);
  }

  private checkActiveTab(title: string): void {
    this.tabs.forEach((tab: TabComponent): void => {
      tab.isActive = tab.title === title;
    });
  }
}
