import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'wp-link',
  templateUrl: 'link.component.html',
  styleUrl: 'link.component.scss',
  imports: [RouterLink],
})
export class LinkComponent {
  @Input() public route: string = '';
}
