import { Routes } from '@angular/router';
import { LoggedInGuard, NotLoggedInGuard } from '@werfy/core';
import { CardLayout, SidebarLayout } from '@werfy/layout';
import { ApplicationRoutes, AuthenticationRoutes } from '@werfy/routing';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canMatch: [NotLoggedInGuard],
    redirectTo: `${ApplicationRoutes.Authentication}/${AuthenticationRoutes.Login}`,
  },
  {
    path: ApplicationRoutes.JoinOrganization,
    canMatch: [NotLoggedInGuard],
    redirectTo: `${ApplicationRoutes.Authentication}/${AuthenticationRoutes.JoinOrganization}`,
  },
  {
    path: ApplicationRoutes.Authentication,
    canMatch: [NotLoggedInGuard],
    loadChildren: () => import('./modules/authentication').then((m) => m.AuthenticationModule),
  },
  {
    path: ApplicationRoutes.JoinOrganization,
    canMatch: [LoggedInGuard],
    component: CardLayout,
    children: [
      // TODO : Add accept invite page
    ],
  },
  {
    path: '',
    component: SidebarLayout,
    canMatch: [LoggedInGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadChildren: () => import('./modules/dashboard').then((m) => m.DashboardModule),
      },
      {
        path: ApplicationRoutes.Tickets,
        loadChildren: () => import('./modules/tickets').then((m) => m.TicketsModule),
      },
      {
        path: ApplicationRoutes.Profiles,
        loadChildren: () => import('./modules/profile').then((m) => m.ProfileModule),
      },
      {
        path: ApplicationRoutes.Organizations,
        loadChildren: () => import('./modules/organization').then((m) => m.OrganizationModule),
      },
      {
        path: ApplicationRoutes.Campaigns,
        loadChildren: () => import('./modules/campaign').then((m) => m.CampaignModule),
      },
      {
        path: ApplicationRoutes.Candidates,
        loadChildren: () => import('./modules/candidate').then((m) => m.CandidateModule),
      },
      {
        path: ApplicationRoutes.Reporting,
        loadChildren: () => import('./modules/reporting').then((m) => m.ReportingModule),
      },
      {
        path: ApplicationRoutes.Templates,
        loadChildren: () => import('./modules/template').then((m) => m.TemplateModule),
      },
    ],
  },
  { path: '**', redirectTo: ApplicationRoutes.Root },
];
