import { NgIf, NgOptimizedImage } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IconComponent } from '../../core';

@Component({
  selector: 'wp-avatar',
  templateUrl: './avatar.component.html',
  styleUrl: './avatar.component.scss',
  imports: [NgOptimizedImage, IconComponent, NgIf],
})
export class AvatarComponent {
  @Input() public url?: string;
}
