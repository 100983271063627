import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  imports: [NgIf],
  selector: 'wp-card',
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss',
})
export class CardComponent {
  @Input() public name?: string;
}
