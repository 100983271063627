import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'wp-chat-message',
  templateUrl: 'chat-message.component.html',
  styleUrl: 'chat-message.component.scss',
  imports: [NgIf],
})
export class ChatMessageComponent {
  @Input() public type: 'self' | 'reply' = 'self';
}
