import { NgIf, NgOptimizedImage } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'wp-user-summary',
  templateUrl: './user-summary.component.html',
  styleUrl: './user-summary.component.scss',
  imports: [NgIf, NgOptimizedImage],
})
export class UserSummaryComponent {
  @Input({ required: true }) public name!: string;
  @Input() public email?: string;
  @Input() public avatarUrl?: string;
}
