import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Style, StyleType } from '@standardkit/local-ui';
import { IconSet, IconSetType } from '@standardkit/ui';
import { IconComponent } from '../icon';

@Component({
  selector: 'wp-button',
  templateUrl: 'button.component.html',
  styleUrl: 'button.component.scss',
  imports: [IconComponent, NgIf],
})
export class ButtonComponent {
  @Input() public type: StyleType | 'linkedin' = Style.Base;
  @Input() public disabled: boolean = false;
  @Input() public fullWidth: boolean = false;
  @Input() public compact: boolean = false; // TODO : Introduce size
  @Input() public isSharp: boolean = false;
  @Input() public icon?: string;
  @Input() public iconSet: IconSetType = IconSet.Light;
  @Input() public alignIcon: 'left' | 'right' = 'left';
  @Input() public flipIcon: boolean = false;
  @Input() public isSubmit: boolean = false;
  @Input() public noShadow: boolean = false;

  @Output() public buttonClick: EventEmitter<void> = new EventEmitter<void>();

  public onClick(): void {
    this.buttonClick.emit();
  }
}
