import { NgIf, TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { State, StateType } from '@standardkit/ui';
import { CreateTicketRequest, CreateTicketResponse, TicketService, TicketType } from '@werfy/core';
import { ApplicationRoutes } from '@werfy/routing';
import {
  AlertComponent,
  BarComponent,
  ButtonComponent,
  FieldComponent,
  FormComponent,
  IconComponent,
  InputDescriptionComponent,
  LabelComponent,
  LinkComponent,
  ModalComponent,
  RadioButtonComponent,
  RadioInputComponent,
  TextareaInputComponent,
  TextInputComponent,
} from '@werfy/ui';
import { CreateTicketForm } from './create-ticket.form';

@Component({
  selector: 'wp-create-ticket-modal',
  templateUrl: './create-ticket.modal.html',
  imports: [
    ModalComponent,
    FormComponent,
    ReactiveFormsModule,
    FieldComponent,
    LabelComponent,
    InputDescriptionComponent,
    RadioInputComponent,
    RadioButtonComponent,
    TextInputComponent,
    TextareaInputComponent,
    BarComponent,
    ButtonComponent,
    AlertComponent,
    IconComponent,
    NgIf,
    LinkComponent,
    TitleCasePipe,
  ],
})
export class CreateTicketModal {
  @Output() public closeModal: EventEmitter<void> = new EventEmitter<void>();

  public form: FormGroup<CreateTicketForm> = new FormGroup({
    type: new FormControl<TicketType | null>(null, [Validators.required]),
    subject: new FormControl<string | null>(null, [Validators.required]),
    description: new FormControl<string | null>(null, [Validators.required]),
  });

  public state: StateType = State.Initial;
  public ticketId?: string;

  constructor(
    private ticketService: TicketService,
    private router: Router,
  ) {}

  public get ticketRoute(): string {
    return `${ApplicationRoutes.Root}${ApplicationRoutes.Tickets}/${this.ticketId}`;
  }

  public onCloseModal(): void {
    this.closeModal.emit();
  }

  public onSubmit(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    // Casting because form is valid
    const request: CreateTicketRequest = this.form.value as CreateTicketRequest;

    this.form.disable();
    this.state = State.Pending;

    this.ticketService.createTicket$(request).subscribe({
      next: (response: CreateTicketResponse) => {
        this.ticketId = response.id;
        this.state = State.Success;
      },
      error: () => {
        this.form.enable();
        this.state = State.Error;
      },
    });
  }

  public onViewTicket(): void {
    this.router.navigate([ApplicationRoutes.Root, ApplicationRoutes.Tickets, this.ticketId]).then(() => {
      this.closeModal.emit();
    });
  }
}
