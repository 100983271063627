import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { StyleType } from '@standardkit/local-ui';
import { IconSet, IconSetType } from '@standardkit/ui';

@Component({
  selector: 'wp-icon',
  templateUrl: 'icon.component.html',
  styleUrl: 'icon.component.scss',
  imports: [NgClass],
})
export class IconComponent {
  @Input() public icon?: string = 'icons';
  @Input() public set: IconSetType = IconSet.Duotone;
  @Input() public isSpinning: boolean = false;
  @Input() public isReverse: boolean = false;
  @Input() public flipX: boolean = false;
  @Input() public flipY: boolean = false;
  @Input() public type?: StyleType; // TODO : Support all types
  // TODO : Add Sharp option
}
