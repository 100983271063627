import { NgIf } from '@angular/common';
import {
  AfterContentInit,
  Component,
  ContentChildren,
  forwardRef,
  Input,
  OnDestroy,
  Optional,
  QueryList,
  Self,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { InputComponent, InputInterface } from '../input';
import { InputErrorComponent } from '../input-error';
import { TagOptionComponent } from '../tag-option';

@Component({
  selector: 'wp-multi-tag-input',
  templateUrl: 'multi-tag-input.component.html',
  styleUrl: 'multi-tag-input.component.scss',
  imports: [InputErrorComponent, NgIf],
  providers: [{ provide: InputComponent, useExisting: forwardRef(() => MultiTagInputComponent), multi: true }],
})
export class MultiTagInputComponent implements ControlValueAccessor, InputInterface, AfterContentInit, OnDestroy {
  @ContentChildren(TagOptionComponent) public options!: QueryList<TagOptionComponent>;

  @Input() public name?: string;

  public isDisabled: boolean = false;
  public subscriptions$: Subscription = new Subscription();
  public onChange!: (value: string[] | null) => void;
  public onTouched!: () => void;
  private value: string[] = [];

  constructor(@Optional() @Self() public control: NgControl) {
    if (control) {
      control.valueAccessor = this;
    }
  }

  public ngAfterContentInit(): void {
    this.options.notifyOnChanges();
    this.options.changes.subscribe(() => this.listenToOptions());
    this.listenToOptions();
  }

  public ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }

  public registerOnChange(onChange: (value: string[] | null) => void): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public writeValue(value: string[] | null): void {
    this.value = value ?? [];
  }

  private listenToOptions(): void {
    this.subscriptions$.unsubscribe();
    this.subscriptions$ = new Subscription();
    this.options.forEach((option: TagOptionComponent) => {
      const selectSubscription$ = option.select.subscribe(() => this.onSelect(option));
      this.subscriptions$.add(selectSubscription$);

      const deselectSubscription$ = option.deselect.subscribe(() => this.onDeselect(option));
      this.subscriptions$.add(deselectSubscription$);
    });
  }

  private onSelect(option: TagOptionComponent): void {
    option.isSelected = true;
    this.value.push(option.value);
    this.onChange(this.value);
    this.onTouched();
  }

  private onDeselect(option: TagOptionComponent): void {
    option.isSelected = false;
    const index = this.value.findIndex((value: string) => value === option.value);
    if (index > -1) {
      this.value.splice(index, 1);
      this.onChange(this.value);
      this.onTouched();
    }
  }
}
