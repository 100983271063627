import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StyleType } from '@standardkit/local-ui';
import { IconComponent } from '@werfy/ui';

@Component({
  selector: 'wp-radio-button',
  templateUrl: 'radio-button.component.html',
  styleUrl: 'radio-button.component.scss',
  imports: [IconComponent, NgClass, NgIf],
})
export class RadioButtonComponent {
  @Input() public name!: string;
  @Input({ required: true }) public label!: string;
  @Input({ required: true }) public value!: string;
  @Input() public type?: StyleType;

  @Output() public selectOption: EventEmitter<void> = new EventEmitter<void>();

  public isSelected: boolean = false;

  // TODO : Handle disabled state

  public onSelect(): void {
    this.selectOption.emit();
  }
}
