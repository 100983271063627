import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CardPageComponent } from '@werfy/ui';

@Component({
  selector: 'wp-card-layout',
  templateUrl: './card.layout.html',
  imports: [RouterOutlet, CardPageComponent],
})
export class CardLayout {
  public year: number = new Date().getFullYear();
}
