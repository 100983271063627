import { NgForOf, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { IconSetType } from '@standardkit/ui';
import { IconComponent } from '../../core';
import { BreadcrumbInterface } from './breadcrumb.interface';

@Component({
  selector: 'wp-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrl: './breadcrumbs.component.scss',
  imports: [NgForOf, NgIf, IconComponent, RouterLink],
})
export class BreadcrumbsComponent {
  @Input() public separator: string = '/';
  @Input() public icon?: string;
  @Input() public iconSet: IconSetType = 'light';
  @Input() public breadcrumbs: BreadcrumbInterface[] = [];
}
