import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'wp-tab',
  templateUrl: 'tab.component.html',
  imports: [NgIf],
})
export class TabComponent {
  @Input({ required: true }) public title!: string;

  public isActive: boolean = false;
}
