import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StyleType } from '@standardkit/local-ui';

@Component({
  selector: 'wp-summary-card',
  templateUrl: 'summary-card.component.html',
  styleUrl: 'summary-card.component.scss',
  imports: [NgClass],
})
export class SummaryCardComponent {
  @Input() public label: string = '';
  @Input() public type?: StyleType;

  @Output() public selectCard: EventEmitter<void> = new EventEmitter<void>();

  public onClick(): void {
    this.selectCard.emit();
  }
}
