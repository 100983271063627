import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'wp-input-description',
  templateUrl: './input-description.component.html',
  styleUrl: './input-description.component.scss',
  imports: [NgIf],
})
export class InputDescriptionComponent {
  @Input() public label?: string;
}
